import React, { Component } from 'react';
import PropTypes from 'prop-types';
import vCard from 'vcf';
import Card from '../Card';
import LinkButton from '../LinkButton';

class ContactPicker extends Component {
  getCardComponent(cardIndex) {
    const card = this.getCard(cardIndex);
    return card ? (
      <Card
        card={card}
        isPicked={this.isCardPicked(card)}
        isRejected={this.isCardRejected(card)}
        cardIndex={cardIndex}
      />
    ) : null;
  }

  getCard(index) {
    if (index === undefined) {
      // eslint-disable-next-line no-param-reassign
      index = this.props.index;
    }
    return this.props.cards[index];
  }

  getRemainingCount() {
    const { cards, picked, rejected } = this.props;
    return cards.length - picked.length - rejected.length;
  }

  getCardIid(card) {
    return (card || this.getCard()).get('iid').valueOf();
  }

  rejectCard = () => {
    const newState = {
      index: this.props.index + 1,
    };
    if (!this.isCardRejected(this.getCard())) {
      const rejected = this.props.rejected.slice();
      rejected.push(this.getCard());
      newState.rejected = rejected;
    }
    if (this.isCardPicked(this.getCard())) {
      newState.picked = this.props.picked.slice()
        .filter((c) => this.getCardIid(c) !== this.getCardIid());
    }
    this.props.storeState(newState);
  };

  pickCard = () => {
    const newState = {
      index: this.props.index + 1,
    };
    if (!this.isCardPicked(this.getCard())) {
      newState.picked = this.props.picked.slice();
      newState.picked.push(this.getCard());
    }
    if (this.isCardRejected()) {
      newState.rejected = this.props.rejected.slice()
        .filter((c) => this.getCardIid(c) !== this.getCardIid());
    }
    this.props.storeState(newState);
  };

  goBack = () => {
    if (this.props.index < 1) {
      return;
    }
    const index = this.props.index - 1;
    this.props.storeState({ index });
  };

  goForward = () => {
    if (this.props.index >= this.props.cards.length) {
      return;
    }
    const index = this.props.index + 1;
    this.props.storeState({ index });
  };

  nextPage = () => {
    this.props.nextPage();
  };

  isCardRejected(card) {
    return this.props.rejected.includes(card);
  }

  isCardPicked(card) {
    return this.props.picked.includes(card);
  }

  exportButton() {
    if (!this.props.picked.length) {
      return null;
    }
    return <p><LinkButton clickHandler={this.nextPage}>Export Selected Contacts</LinkButton></p>;
  }

  render() {
    return (
      <div>
        <h3>Step 3: Choose Contacts to Keep</h3>
        <div className="card-container">
          {this.getCardComponent(this.props.index)}
        </div>
        <div className="action-buttons">
          <button type="button" onClick={this.goBack}>« Back</button>
          <button type="button" onClick={this.goForward}>Forward »</button>
          <button type="button" className="reject" onClick={this.rejectCard}>Discard</button>
          <button type="button" className="keep" onClick={this.pickCard}>Keep</button>
        </div>
        <table className="card-stats">
          <thead>
            <tr>
              <td>Total</td>
              <td>Kept</td>
              <td>Discarded</td>
              <td>Remaining</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.props.cards.length}</td>
              <td>{this.props.picked.length}</td>
              <td>{this.props.rejected.length}</td>
              <td>{this.getRemainingCount()}</td>
            </tr>
          </tbody>
        </table>
        {this.exportButton()}
      </div>
    );
  }
}

ContactPicker.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.instanceOf(vCard)).isRequired,
  picked: PropTypes.arrayOf(PropTypes.instanceOf(vCard)),
  rejected: PropTypes.arrayOf(PropTypes.instanceOf(vCard)),
  index: PropTypes.number,
  storeState: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
};

ContactPicker.defaultProps = {
  index: 0,
  picked: [],
  rejected: [],
};

export default ContactPicker;
