import React, { Component } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class LinkButton extends Component {
  render() {
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    return (
      <button
        type="button"
        onClick={() => this.props.clickHandler()}
        style={{
          background: 'transparent',
          border: 'none',
          color: 'blue',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {children}
      </button>
    );
  }
}

LinkButton.propTypes = {
  clickHandler: PropTypes.func,
};

LinkButton.defaultProps = {
  clickHandler: () => {},
};

export default LinkButton;
