import './App.scss';
import CurrentStep from './Steps/CurrentStep';

function App() {
  return (
    <div className="App">
      <CurrentStep />
    </div>
  );
}

export default App;
