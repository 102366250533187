import React, { Component } from 'react';
import HowToExport from './HowToExport';
import LoadFile from './LoadFile';
import ContactPicker from './ContactPicker';
import ExportContacts from './ExportContacts';

class CurrentStep extends Component {
  state = {
    step: 0,
    ecosystem: '',
    cards: [],
    picked: [],
    rejected: [],
    index: 0,
  };

  getResetButton() {
    if ((process.env.NODE_ENV || '') === 'development') {
      return <button id="reset-button" onClick={this.resetContacts} type="button">Reset</button>;
    }
    return null;
  }

  getCurrentStep() {
    switch (this.state.step) {
      case 0:
        return (
          <HowToExport
            nextStep={this.nextStep}
            storeState={this.storeState}
            ecosystem={this.state.ecosystem}
          />
        );
      case 1:
        return <LoadFile loadNext={this.nextStep} storeState={this.storeState} />;
      case 2:
        return (
          <ContactPicker
            cards={this.state.cards}
            storeState={this.storeState}
            index={this.state.index}
            picked={this.state.picked}
            rejected={this.state.rejected}
            nextPage={this.nextStep}
          />
        );
      case 3:
        return <ExportContacts contacts={this.state.picked} />;
      default:
        return null;
    }
  }

  storeState = (key, value) => {
    if (key === Object(key) && Object.prototype.toString.call(key) !== '[object Array]') {
      this.setState((previous) => ({ ...previous, ...key }));
    } else {
      const newState = {};
      newState[key] = value;
      this.setState((previous) => ({ ...previous, ...newState }));
    }
  };

  nextStep = () => {
    this.setState((prevState) => ({ ...prevState, step: prevState.step + 1 }));
  };

  resetContacts = () => {
    this.setState({
      step: 1,
      cards: [],
      picked: [],
      rejected: [],
      index: 0,
    });
  };

  render() {
    return (
      <div>
        {this.getResetButton()}
        {this.getCurrentStep()}
      </div>
    );
  }
}

export default CurrentStep;
