import React, { Component } from 'react';
import PropTypes from 'prop-types';
import vCard from 'vcf';
import format from './format';

class Card extends Component {
  field(property, label, formatter, fieldIndex) {
    const idParts = [`card${this.props.cardIndex}`, label.toLowerCase()];
    if (property.type) {
      idParts.push((property.type.join ? property.type.join('-') : property.type).toLowerCase());
    }
    idParts.push(fieldIndex || '0');
    return (
      <p key={idParts.join('-')}>
        <strong>
          {label}
          {property.type ? ` (${property.type})` : ''}
          :
        </strong>
        {` ${formatter.call(this, property)}`}
      </p>
    );
  }

  singleField(fieldName, label, _formatter) {
    let data = this.props.card.get(fieldName);
    const formatter = _formatter || ((datum) => datum.valueOf());
    if (!(data && (data.length || data.toJSON))) {
      return null;
    }
    if (data.length) {
      [data] = data;
    }
    return this.field(data, label, formatter);
  }

  arrayableField(fieldName, label, _formatter) {
    let data = this.props.card.get(fieldName);
    const formatter = _formatter || ((datum) => datum.valueOf());
    if (!(data && (data.length || data.toJSON))) {
      return null;
    }
    if (data.toJSON) {
      data = [data];
    }
    const list = [];
    data.forEach((datum, index) => {
      list.push(this.field(datum, label, formatter, index));
    });
    return list;
  }

  renderStatus() {
    if (this.props.isPicked) {
      return <h5 style={{ color: '#00f' }}>Marked to keep</h5>;
    }
    if (this.props.isRejected) {
      return <h5 style={{ color: '#f00' }}>Marked to discard</h5>;
    }
    return null;
  }

  render() {
    return (
      <div className="contact-card">
        <aside>
          {this.renderStatus()}
          {this.singleField('n', 'Name', (datum) => format.vCardName(datum.toJSON()[3]))}
          {this.arrayableField('tel', 'Phone')}
          {this.arrayableField('adr', 'Address', (datum) => format.vCardAddress(datum.toJSON()[3]))}
          {this.arrayableField('email', 'Email')}
        </aside>
      </div>
    );
  }
}

Card.propTypes = {
  card: PropTypes.instanceOf(vCard).isRequired,
  isPicked: PropTypes.bool,
  isRejected: PropTypes.bool,
  cardIndex: PropTypes.number.isRequired,
};

Card.defaultProps = {
  isPicked: false,
  isRejected: false,
};

export default Card;
