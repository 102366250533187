import React, { Component } from 'react';
import PropTypes from 'prop-types';
import vCard from 'vcf';
import { CSVDownload, CSVLink } from 'react-csv';
import format from '../format';

class ExportContacts extends Component {
  getData() {
    const dataSet = [];
    this.props.contacts.forEach((item) => dataSet.push(format.cardToCsvRow(item)));
    return dataSet;
  }

  render() {
    return (
      <div>
        <h2>Step 4: Download Contacts</h2>
        <p>
          Your download will start automatically! If it doesn’t,
          <CSVLink
            data={this.getData()}
            headers={format.getHeaders()}
            filename="filtered-contacts.csv"
          >
            try clicking this link
          </CSVLink>
          .
        </p>
        <CSVDownload data={this.getData()} headers={format.getHeaders()} />
      </div>
    );
  }
}

ExportContacts.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.instanceOf(vCard)).isRequired,
};

export default ExportContacts;
