import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iphone1 from '../assets/export-contacts-from-iphone-2.jpg';
import iphone2 from '../assets/icloud-select-all.png';
import iphone3 from '../assets/icloud-export.png';
import android12 from '../assets/android-1-2.jpg';
import android13 from '../assets/android-1-3.jpg';
import android21 from '../assets/android-account-sync.jpg';
import android22 from '../assets/android-export-web.png';
import android23 from '../assets/android-export-modal.png';
import LinkButton from '../LinkButton';

class HowToExport extends Component {
  setAndroidEcosystem = () => {
    this.props.storeState('ecosystem', 'android');
  };

  setIphoneEcosystem = () => {
    this.props.storeState('ecosystem', 'iphone');
  };

  goToNextStep = () => {
    this.props.nextStep();
  };

  // eslint-disable-next-line class-methods-use-this
  wrapContents(contents) {
    return (
      <div>
        <h2>Step 1: Export Contacts</h2>
        {contents}
      </div>
    );
  }

  ecosystemPicker() {
    return this.wrapContents(
      <div>
        {this.nextStepNavButton('I have already exported my contacts')}
        <p>What kind of phone do you use?</p>
        <div className="phone-picker">
          <button type="button" id="iphone" onClick={this.setIphoneEcosystem}>iPhone</button>
          <button type="button" id="android" onClick={this.setAndroidEcosystem}>Android</button>
        </div>
      </div>,
    );
  }

  nextStepNavButton(text) {
    return <LinkButton clickHandler={this.goToNextStep}>{text}</LinkButton>;
  }

  androidInstructions() {
    return this.wrapContents(
      <div>
        <p>
          There are two different ways to export contacts in Android. Depending on what
          version you have, the features could be missing, or look different from the
          screenshots shown below.
        </p>
        <p>&nbsp;</p>
        <h3>Method 1:</h3>
        <p>
          <strong>Step 1: </strong>
          In your Android phone, open the Contacts app and tap the gear icon to get to the
          settings menu.
        </p>
        <p>
          <strong>Step 2: </strong>
          Towards the bottom of the settings menu, go to “Import/Export”
        </p>
        <p><img src={android12} alt="" style={{ width: '400px' }} /></p>
        <p>
          <strong>Step 3: </strong>
          Select “Export to .vcf file”. Choose where to save your export and start the export.
        </p>
        <p><img src={android13} alt="" style={{ width: '400px' }} /></p>
        <p>&nbsp;</p>
        <hr />
        <p>&nbsp;</p>
        <h3>Method 2:</h3>
        <p>
          <strong>Step 1: </strong>
          Open the settings app, navigate to Accounts, select the account you wish to use to
          export your contacts, and select Account Sync. Ensure contact syncing is enabled for
          that account.
        </p>
        <p><img src={android21} alt="" style={{ width: '400px' }} /></p>
        <p>
          <strong>Step 2: </strong>
          On a computer, go to &nbsp;
          <a href="https://contacts.google.com/" target="_blank" rel="noreferrer">
            https://contacts.google.com/
          </a>
          &nbsp; and select Export in the left-hand menu
        </p>
        <p><img src={android22} alt="" /></p>
        <p>
          <strong>Step 3: </strong>
          Make sure you are exporting all contacts and under Export As, choose “vCard”.
        </p>
        <p><img src={android23} alt="" /></p>
        <p>
          <strong>Step 4: </strong>
          Transfer the export to the device you plan to use to work on your contacts.
        </p>
        {this.nextStepNavButton('Ok, I\'ve exported my contacts and am ready to sift through them.')}
      </div>,
    );
  }

  iphoneInstructions() {
    return this.wrapContents(
      <div>
        <p>
          <strong>Step 1: </strong>
          In your iPhone, navigate to ‘Settings ➡ Name ➡ iCloud’. Then ensure the ‘Contacts’
          option is enabled.
        </p>
        <p><img src={iphone1} alt="" /></p>
        <p>
          <strong>Step 2: </strong>
          <a href="https://www.icloud.com/contacts/" target="_blank" rel="noreferrer">
            Log into icloud from a computer
          </a>
          &nbsp;and go to the contacts panel (https://www.icloud.com/contacts).
          Click the cog in the bottom left corner and click “Select All.”
        </p>
        <p><img src={iphone2} alt="" /></p>
        <p>
          <strong>Step 3: </strong>
          Click the cog again and this time click “Export vCard...” to download your contacts.
        </p>
        <p><img src={iphone3} alt="" /></p>
        <p>
          <strong>Step 4: </strong>
          If you want to work on your contacts on your phone or another computer,
          transfer the file to the device you want to work from. For example, you
          could email the file to yourself and download the attachment on your phone.
        </p>
        {this.nextStepNavButton('Ok, I\'ve exported my contacts and am ready to sift through them.')}
      </div>,
    );
  }

  render() {
    if (!this.props.ecosystem) {
      return this.ecosystemPicker();
    }
    return (this.props.ecosystem === 'android') ? this.androidInstructions() : this.iphoneInstructions();
  }
}

HowToExport.propTypes = {
  nextStep: PropTypes.func.isRequired,
  storeState: PropTypes.func.isRequired,
  ecosystem: PropTypes.string,
};

HowToExport.defaultProps = {
  ecosystem: '',
};

export default HowToExport;
