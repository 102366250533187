import React, { Component } from 'react';
import PropTypes from 'prop-types';
import vCard from 'vcf';

function maybeNormalizeCard(card) {
  return card.replace(/(?<!\r)\n/g, '\r\n');
}

function maybeAddCardToList(card, list) {
  try {
    // eslint-disable-next-line new-cap
    const vc = new vCard();
    vc.parse(maybeNormalizeCard(card));
    list.push(vc);
  } catch (error) {
    window.console.log('Failed to parse a vCard', card);
  }
}

class LoadFile extends Component {
  readExportFile = (e) => {
    if (!e.target.files.length) {
      return;
    }
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const stringCards = (`${reader.result}`).split(/(?=BEGIN:VCARD)/gi);
      const rawCards = [];
      for (let i = 0; i < stringCards.length; i += 1) {
        maybeAddCardToList(stringCards[i], rawCards);
      }
      const cards = rawCards.filter((card) => !!(card.get('tel') || card.get('adr') || card.get('email')));
      this.goToNextStep(cards);
    });
    reader.readAsText(file);
  };

  goToNextStep(cards) {
    cards.forEach((card, index) => { card.add('iid', index, {}); });
    this.props.storeState('cards', cards);
    this.props.loadNext();
  }

  render() {
    return (
      <div>
        <h2>Step 2: Load contacts export</h2>
        <p>
          Use the field below to load your contacts export into this tool.
          Your export won’t ever be shared, leaked, saved, or transered to
          a database somewhere. It never even leaves this computer.
        </p>
        <p>
          We’ll automatically filter out anybody who doesn’t have an email,
          phone, or address in the contact.
        </p>
        <p><input id="load-file" type="file" onChange={this.readExportFile} /></p>
      </div>
    );
  }
}

LoadFile.propTypes = {
  loadNext: PropTypes.func.isRequired,
  storeState: PropTypes.func.isRequired,
};

export default LoadFile;
