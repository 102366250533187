function arrayIncludesAll(arrayToTest, values) {
  const filtered = arrayToTest.filter((item) => values.includes(item));
  return filtered.length === values.length;
}

function arrayWrap(thing) {
  return thing.constructor === Array ? thing : [thing];
}

function getVcardFirstByType(vCard, fieldName, type) {
  let field = vCard.get(fieldName);
  if (!field) {
    return null;
  }
  if (field.length === undefined) {
    if (!type) {
      return field;
    }
    field = [field];
  }
  // eslint-disable-next-line no-param-reassign
  type = arrayWrap(type);
  for (let index = 0; index < type.length; index += 1) {
    const tp = arrayWrap(type[index]);
    if (tp[0] === false) {
      return field[0];
    }
    const filtered = field.filter((p) => p.type && arrayIncludesAll(arrayWrap(p.type), tp));
    if (filtered.length) {
      return filtered[0];
    }
  }
  return null;
}

export default {
  vCardAddress(pieces) {
    const [poBox, addr2, addr, city, state, zip, country] = pieces;
    let addressFull = '';
    if (poBox) {
      addressFull = `P.O. Box ${poBox.replace(/\D/, '')}`;
    } else if (addr) {
      addressFull = addr;
      if (addr2) {
        addressFull = `${addressFull}, ${addr2}`;
      }
    }
    let sep = '';
    if (city) {
      sep = addressFull ? ', ' : '';
      addressFull = `${addressFull}${sep}${city}`;
    }
    if (state) {
      sep = addressFull ? ', ' : '';
      addressFull = `${addressFull}${sep}${state}`;
    }
    if (zip) {
      sep = addressFull ? ' ' : '';
      addressFull = `${addressFull}${sep}${zip}`;
    }
    if (country) {
      sep = addressFull ? ', ' : '';
      addressFull = `${addressFull}${sep}${country}`;
    }
    return addressFull;
  },

  vCardName(pieces) {
    const [last, first, middle, prefix, suffix] = pieces;
    const formatMultiple = (thing, glue) => thing.split(',').join(glue || ' ');
    const fullNameParts = [];
    if (prefix) {
      fullNameParts.push(formatMultiple(prefix));
    }
    if (first) {
      fullNameParts.push(formatMultiple(first));
    }
    if (middle) {
      fullNameParts.push(formatMultiple(middle));
    }
    if (last) {
      fullNameParts.push(formatMultiple(last, '-'));
    }
    if (suffix) {
      fullNameParts.push(formatMultiple(suffix, ', '));
    }
    return fullNameParts.join(' ');
  },

  getHeaders() {
    return [
      { label: 'Prefix', key: 'prefix' },
      { label: 'First Name', key: 'first' },
      { label: 'Last Name', key: 'last' },
      { label: 'Suffix', key: 'suffix' },
      { label: 'Phone', key: 'phone' },
      { label: 'Address', key: 'addr' },
      { label: 'Address 2', key: 'addr2' },
      { label: 'City', key: 'city' },
      { label: 'State', key: 'state' },
      { label: 'Zip Code', key: 'zip' },
      { label: 'Email', key: 'email' },
    ];
  },

  cardToCsvRow(card) {
    let exportData = {
      prefix: '',
      first: '',
      last: '',
      suffix: '',
      phone: '',
      addr: '',
      addr2: '',
      city: '',
      state: '',
      zip: '',
      email: '',
    };
    let name = card.get('n');
    if (name) {
      name = name.length ? name[0] : name;
      const [last, first, , prefix, suffix] = name.toJSON()[3];
      exportData.prefix = prefix;
      exportData.last = last;
      exportData.first = first;
      exportData.suffix = suffix;
    }
    const phone = getVcardFirstByType(card, 'tel', [['pref', 'cell'], 'cell', 'pref', false]);
    if (phone) {
      exportData.phone = phone.valueOf().replace(/^\+1/, '1');
    }
    const address = getVcardFirstByType(card, 'adr', [['pref', 'home'], 'home', false]);
    if (address) {
      const [, addr2, addr, city, state, zip] = address.toJSON()[3];
      exportData = {
        ...exportData, addr, addr2, city, state, zip,
      };
    }
    const email = getVcardFirstByType(card, 'email', [['pref', 'home'], 'home', 'pref', false]);
    if (email) {
      exportData.email = email.valueOf();
    }
    return exportData;
  },
};
